<template>
  <iq-card>
    <template v-slot:body>
      <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="convertedProfileStatus" :profile-url="'/warehouse-farmer/profile'" :inForm="true" :needApproval="true"/>
      <!-- <form-pre-condition :panel="'warehouse'" :preConditionLoader="preConditionLoader" :approvedStatus="approveStatus" :profileStatus="profileStatus" :profile-url="'/warehouse-farmer/profile'"/> -->
      <b-overlay :show="loading" rounded="sm">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" @click="displayProfileCheckAlert">
          <b-row>
              <b-col lg="6" sm="6">
          <ValidationProvider name="Farmer Id" rules="required|min_value:1">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="farmer_id"
              slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('warehouse_service.farmer_id')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                readonly
                id="farmer_id"
                v-model="farmer_request.farmer_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Farmer Name">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="farmer_name"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_service.farmer_name')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="farmer_name"
                v-model="farmer_info.farmer_name"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Father Name">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="father_name"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_service.father_name')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="father_name"
                v-model="farmer_info.father_name"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Address">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="address"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_service.address')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="address"
                v-model="farmer_info.address"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Division" vid='office_name'>
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="warehouse_name"
              slot-scope="{ valid, errors }"
            >
              <template v-slot:label>
                {{ $t('globalTrans.division')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                id="warehouse_name"
                readonly
                v-model="farmer_info.division_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Region">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="region"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('globalTrans.region')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="region_id"
                v-model="farmer_info.region_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="District">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="district"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('globalTrans.district')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="district_id"
                v-model="farmer_info.district_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="6">
              <ValidationProvider name="Upazila">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="upazila"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('globalTrans.upazila')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="upazila_id"
                v-model="farmer_info.upazilla_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
              <ValidationProvider name="Union">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="union"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('globalTrans.union')}} <span class="text-danger"></span>
              </template>
              <b-form-input
                  readonly
                id="union_id"
                v-model="farmer_info.union_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
              <ValidationProvider name="Warehouse Name">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="warehouse_name"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_service.warehouse_name')}} <span class="text-danger"></span>
              </template>
                <b-form-input
                  readonly
                id="warehouse_id"
                v-model="farmer_info.warehouse_id"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Crop Type" rules="required|min_value:1">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="crop_type"
              slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('warehouse_service.crop_type')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="farmer_request.crop_type_id"
                :options="cropTypeList"
                id="crop_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Crop Name" rules="required|min_value:1">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="crop_name"
              slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('warehouse_service.crop_name')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="farmer_request.crop_id"
                :options="cropNameLists"
                id="upazila_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Sack Number" vid='sack_number' rules="required">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="sack_number"
              slot-scope="{ valid, errors }"
            >
              <template v-slot:label>
                {{ $t('warehouse_service.sack_number')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="sack_number"
                type="number"
                v-model="farmer_request.sack_quantity"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Total Quantity" rules="required">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="total_quantity"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_service.total_quantity')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="total_quantity"
                type="number"
                v-model="farmer_request.total_quantity"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
            <b-row v-for="(setLevel, index) in farmer_request.farmer_level" :key="index">
          <b-col lg="6" sm="6">
          </b-col>
            </b-row>
          </b-col>
          <b-col class="text-right">
              <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
          </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </b-overlay>
    </template>
  </iq-card>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { warefarmerSelfRequestStore, warehouseFarmerStatus } from '../../../api/routes'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    ProfileStatusMsg
  },
  created () {
    this.checkApprovedUser()
    if (this.id) {
      const tmp = this.getFarmerRequestData()
      // Object.freeze(tmp)
      this.farmer_request = tmp
    }
  },
  mounted () {
    core.index()
    this.loadData()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      preConditionLoader: true,
      approveStatus: 0,
      profileStatus: 0,
      convertedProfileStatus: 0,
      farmer_request: {
        warehouse_id: '',
        farmer_id: '',
        sack_quantity: '',
        total_quantity: '',
         farmer_level: [
            {
            level_id: 0,
            level_quantity: '',
            level_space: ''
            }
        ],
        store_date: '',
        crop_type_id: '0',
        crop_id: '0',
        status: 0,
        created_by: 1,
        updated_by: 1
      },
      farmer_info: {
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_id: '',
        address: '',
        farmer_name: '',
        father_name: '',
        holding_capacity: ''
      },
      cropNameLists: [],
      levelList: [],
      capacity: '',
      district: [],
      dList: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    loading () {
      // return this.$store.state.ExternalUserIrrigation.static.loading
      return this.$store.state.commonObj.loading
    },
    listReload () {
      // return this.$store.state.ExternalUserIrrigation.static.listReload
      return this.$store.state.commonObj.listReload
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    cropTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.filter(item => item.status === 0).map(item => {
        return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'farmer_request.farmer_id': function (newVal, oldVal) {
      this.dList = this.getFarmerInfoList(newVal)
    },
    'farmer_request.crop_type_id': function (newVal, oldVal) {
      this.cropNameLists = this.getCropNameList(newVal)
    },
    'farmer_request.warehouse_id': function (newVal, oldVal) {
      this.levelList = this.getLevelList(newVal)
    }
  },
  methods: {
    async checkApprovedUser () {
        this.preConditionLoader = true
        // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        this.convertedProfileStatus = 0
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, warehouseFarmerStatus + authUser)
        this.preConditionLoader = false
        // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        if (!result.success) {
          return
        }
        this.approveStatus = result.data.status
        this.profileStatus = result.data.save_status
        if (this.profileStatus === 2 && this.approveStatus === 1) {
          this.convertedProfileStatus = 4
        } else if (this.profileStatus === 2 && this.approveStatus !== 1) {
          this.convertedProfileStatus = 1
        }
    },
      add () {
      const stepName = {
        level_id: 0,
        level_quantity: ''
      }
      this.farmer_request.farmer_level.push(stepName)
    },
    remove (key) {
      this.farmer_request.farmer_level.splice(key, 1)
    },
    test (index) {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/list-all').then(response => {
        if (response.success) {
            const levelId = this.farmer_request.farmer_level[index].level_id
          const dataObject = response.data.filter(document => document.id === levelId)
         // this.farmer_request.farmer_level[index].level_space = dataObject[0].level_space
         this.$set(this.farmer_request.farmer_level[index], 'level_space', dataObject[0].level_space)
        }
      })
    },
    getFarmerRequestData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      // if (this.approveStatus === 1) {
      //   this.displayProfileCheckAlert()
      //   return
      // }
      this.displayProfileCheckAlert()
      // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const loadingState = { loading: false, listReload: false }
      let result = null
      try {
          result = await RestApi.postData(warehouseServiceBaseUrl, warefarmerSelfRequestStore, this.farmer_request)
      } catch (error) {
          this.$toast.error({
          title: 'Error',
          message: 'Please, try again.',
          color: '#FFE09B'
          })
      }

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.farmer_request.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        // this.$socket.emit('send-notification', result.admin_notification)
        this.$bvModal.hide('modal-4')
        loadingState.listReload = true
        // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadingState)
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        if (result.message) {
          this.$toast.error({
            title: 'Warning',
            message: result.message
          })
        } else {
          this.$refs.form.setErrors(result.errors)
            this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        this.$store.dispatch('mutateCommonProperties', loadingState)
        // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadingState)
      }
      // this.$store.dispatch('mutateCommonProperties', loadingState)
    },
    getLevelList (warehouseId = null) {
    RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-farmer-req-entry/levellist/' + warehouseId).then(response => {
        if (response.success) {
            this.levelList = response.data.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.level_name_bn }
                } else {
                    return { value: obj.id, text: obj.level_name }
                }
            })
        }
      })
    },
    getCropNameList (cropTypeId = null) {
    const cropList = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(item => item.status === 0).map(item => {
        const customItem = { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn }
        return Object.assign({}, item, customItem)
      })
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    },
    getFarmerInfoList (farmerId = null) {
        RestApi.getData(authServiceBaseUrl, '/user/farmer-profile/' + farmerId).then(response => {
        if (response.success) {
            this.farmer_info.division_id = this.getDataById(response.data.division_id, 'division')
            this.farmer_info.region_id = this.getDataById(response.data.region_id, 'region')
            this.farmer_info.district_id = this.getDataById(response.data.district_id, 'district')
            this.farmer_info.upazilla_id = this.getDataById(response.data.upazilla_id, 'upazila')
            this.farmer_info.union_id = this.getDataById(response.data.union_id, 'union')
            this.farmer_info.warehouse_id = this.getDataById(response.data.warehouse_id, 'warehouse')
            const farmerRequestWarehouseAddress = response.data.address
            const farmerRequestWarehouseAddressBn = response.data.address_bn
            const data = response.data
            this.farmer_request.warehouse_id = data.warehouse_id

            if (this.$i18n.locale === 'bn') {
                this.farmer_info.address = farmerRequestWarehouseAddressBn
                this.farmer_info.farmer_name = data.name_bn
                this.farmer_info.father_name = data.father_name_bn
            } else {
                this.farmer_info.address = farmerRequestWarehouseAddress
                this.farmer_info.farmer_name = data.name
                this.farmer_info.father_name = data.father_name
            }
        }
      })
    },
    loadData () {
        // RestApi.getData(authServiceBaseUrl, '/auth-user').then(response => {
        // if (response.success) {
        //   this.farmer_request.farmer_id = response.data.user_detail_farmer.mobile_no
        //   }
        // })
        this.farmer_request.farmer_id = this.authUser.username ? this.authUser.username : null
    },
    getDataById (Id, type) {
        if (Id) {
            var data = null
            switch (type) {
                case 'division':
                  data = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'district':
                  data = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(document => document.value === Id)
                  break
                case 'upazila':
                  data = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(document => document.value === Id)
                  break
                case 'union':
                  data = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(document => document.value === Id)
                  break
                case 'region':
                  data = this.$store.state.ExternalUserIrrigation.warehouse.regionList.find(document => document.value === Id)
                  break
                case 'warehouse':
                  data = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(document => document.value === Id)
                  break
                case 'cropType':
                  data = this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.find(document => document.value === Id)
                  break
                case 'cropName':
                  data = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.find(document => document.value === Id)
                  break
                default:
                  break
            }
            if (data) {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return null
            }
        } else {
            return null
        }
    },
    displayProfileCheckAlert () {
      if (this.approveStatus === 1) {
          return
      }
      // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
      let msg = this.$t('externalPanel.profile_complete_msg')
      if (parseInt(this.profileStatus === 2 && this.approveStatus) !== 1) {
        msg = this.$t('externalPanel.profile_approval_msg')
      }
      this.$swal({
        title: msg,
        showCloseButton: true,
        confirmButtonText: 'Close',
        // showCancelButton: true,
        // html: msg
        focusConfirm: false
      })
    }
  }
}
</script>
